import cx from "classnames";
import { UilAngleLeft as ArrowLeftIcon } from "@iconscout/react-unicons";

const NavigationArrow = ({ className, direction, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "absolute top-0 bottom-0 z-[1] my-auto flex h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-60 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100",
        direction === "left" ? "left-2.5" : "right-2.5",
        className
      )}
    >
      <ArrowLeftIcon size={30} className={cx(direction === "right" ? "rotate-180" : null)} />
    </div>
  );
};

export default NavigationArrow;
