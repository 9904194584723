import React, { useState, useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { useWindowSize } from "react-use";

import NavigationArrow from "components/navigation-arrow";

const round = (num) => {
  return num - Math.round(num) > 0.1 ? Math.ceil(num) : Math.round(num);
};

const DOT_COLOR = {
  accent: "accent",
  white: "white",
};

const SnapSlider = ({
  children,
  className,
  itemClassName,
  arrowClassName,
  dotsClassName,
  scrollByOneSlide = false,
  childrenClassName,
  dotColor = DOT_COLOR.accent,
  showBgBlack = false,
  bgBlack = <div />,
  iconAvailableInActiveSlide = <div />,
  onClick,
}) => {
  const windowSize = useWindowSize();

  const [node, setNode] = useState(null);
  const [activeDot, setActiveDot] = useState(0);
  const [dotsCount, setDotsCounts] = useState(0);

  const dotClassNameActive = {
    [DOT_COLOR.accent]: "before:text-accent-700",
    [DOT_COLOR.white]: "before:text-white",
  };
  const dotClassNameInactive = {
    [DOT_COLOR.accent]: "before:text-accent-200",
    [DOT_COLOR.white]: "before:text-white/30",
  };

  const childrenLength = children?.length;

  const ref = useCallback((node) => {
    if (node) {
      setNode(node);

      if (scrollByOneSlide) {
        setDotsCounts(childrenLength);
      } else {
        setDotsCounts(round(node.scrollWidth / windowSize.width));
      }
    }
  }, []);

  const onPrevClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (node) {
        let left = node.scrollLeft - windowSize.width;

        if (scrollByOneSlide) {
          left = node.scrollLeft - windowSize.width / childrenLength;
        }

        node.scroll({
          left,
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [node, windowSize.width, activeDot]
  );

  const onNextClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (node) {
        let left = node.scrollLeft + windowSize.width;

        if (scrollByOneSlide) {
          if (node.scrollLeft + windowSize.width / childrenLength >= windowSize.width) {
            left = windowSize.width + windowSize.width / childrenLength;
          } else {
            left = node.scrollLeft + windowSize.width / childrenLength;
          }
        }

        node.scroll({
          left,
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [node, windowSize.width, activeDot]
  );

  const onScroll = useCallback(
    debounce(() => {
      if (node) {
        const { scrollLeft } = node;

        if (scrollByOneSlide) {
          const slideWidth = windowSize.width / childrenLength;
          const slideIndex = Math.floor(scrollLeft / slideWidth);

          setActiveDot(slideIndex > childrenLength - 1 ? childrenLength - 1 : slideIndex);
        } else {
          setActiveDot(round(scrollLeft / windowSize.width));
        }
      }
    }, 25),
    [node, windowSize.width]
  );

  const onDotClick = useCallback(
    (dot) => {
      setActiveDot(dot);

      let left = dot * windowSize.width;

      if (scrollByOneSlide) {
        if (dot === 0) {
          left = 0;
        } else if (dot === dotsCount - 1) {
          left = windowSize.width;
        } else {
          left = dot * (windowSize.width / childrenLength);
        }
      }

      node.scroll({
        left,
        top: 0,
        behavior: "smooth",
      });
    },
    [node]
  );

  if (!children?.length) return null;

  return (
    <div className={cx("group relative", className)} onClick={onClick}>
      <div
        ref={ref}
        onScroll={onScroll}
        className={cx(
          "flex snap-x snap-mandatory items-center overflow-x-auto overflow-y-hidden scrollbar-hide",
          childrenClassName
        )}
      >
        {children.map((node, i) => (
          <div key={i} className={cx("relative flex h-full snap-start justify-center", itemClassName)}>
            {showBgBlack &&
              activeDot !== i &&
              React.cloneElement(bgBlack, {
                onClick: (e) => {
                  activeDot < i && onNextClick(e);
                  activeDot > i && onPrevClick(e);
                },
              })}
            {activeDot === i && iconAvailableInActiveSlide}
            {node}
          </div>
        ))}
      </div>
      {activeDot > 0 && (
        <NavigationArrow className={cx("hidden lg:flex", arrowClassName)} direction="left" onClick={onPrevClick} />
      )}
      {activeDot < dotsCount - 1 && (
        <NavigationArrow className={cx("hidden lg:flex", arrowClassName)} direction="right" onClick={onNextClick} />
      )}
      {dotsCount > 0 && (
        <ul
          className={cx(
            "list-style-none absolute bottom-[-25px] flex w-full justify-center text-center",
            dotsClassName
          )}
        >
          {[...Array(dotsCount).keys()].map((dot) => (
            <li key={dot} className="relative inline-block h-5 w-5 cursor-pointer">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDotClick(dot);
                }}
                className={cx(
                  "block h-5 w-5 cursor-pointer border-0 bg-transparent p-1 text-transparent outline-none",
                  "before:absolute before:top-0 before:left-0 before:h-5 before:w-5 before:text-center before:text-5xl before:leading-5 before:content-['•']",
                  activeDot === dot ? dotClassNameActive[dotColor] : dotClassNameInactive[dotColor]
                )}
              >
                {dot}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SnapSlider.propTypes = {
  children: PropTypes.node,
};

export default SnapSlider;
